import { template as template_7d36841c500e45c790f31e4981426594 } from "@ember/template-compiler";
const FKLabel = template_7d36841c500e45c790f31e4981426594(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
