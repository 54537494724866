import { template as template_4e926ecfdec648e2b4c69ef4041f0e3e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_4e926ecfdec648e2b4c69ef4041f0e3e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
